// <div className="footer">
// <div className="footer__links layout-container">
//   <div className="footer__links__group">
//     <h1>QUICKIES</h1>
//     <ul>


$social-media-width: 18px;

.footer {
  width: 100%;
  background-color: $border-color;
  @extend .u-padding--small--sides;
}
.footer__links {
  @include soft--ends;
  display: flex;
  flex-flow: row wrap;
}
    .footer__links__group {
        flex-basis: 25%;

        & > h3 {
            font-weight: 600;
            font-size: $fs-h5;
            text-transform: uppercase;
            margin-bottom: 0px;
        }
        & > a {
            line-height: 1.5em;
            display: block;
            text-transform: capitalize;
            font-size: $fs-milli;
            & > i {
                margin-right: 10px;
                width: 10px;
            }
        }
        & > p {
            line-height: 1.5em;
            font-size: $fs-milli;
            letter-spacing: 1px;
            margin-bottom: 0;
        }

      & > .social {
        display: flex;
        align-items: center;
        svg {
          width: $social-media-width;
          height: auto;
          @include u-margin--smaller--right;
        }

        &:hover {
          svg {
            .st0 {
              fill: $clr-primary;
            }
          }
        }
      }
    }

.footer__copyright {
    padding: 7px 0;
    text-transform: capitalize;
    letter-spacing: 2px;
    font-size: 11px;
    text-align: center;
}

@include media-query(portable){
  .footer__links__group {
    flex-basis: 50%;
    @include push--ends;

    &:nth-child(5) {
      flex-basis: 100%;
      text-align: center;
    }
  }
}

