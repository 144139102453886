/*------------------------------------*\
    $COLLAPSED ANCHOR
\*------------------------------------*/
@mixin menu-anchor-collapse() {
  border-width: 0;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  padding-bottom: 0;
  padding-top: 0;
}
