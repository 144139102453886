/*------------------------------------*\
    PADDING UTILITIES
\*------------------------------------*/
 .u-padding--large         { @include u-padding--large;}
 .u-padding--large--top    { @include u-padding--large--top;}
 .u-padding--large--right  { @include u-padding--large--right;}
 .u-padding--large--bottom { @include u-padding--large--bottom;}
 .u-padding--large--left   { @include u-padding--large--left;}
 .u-padding--large--ends   { @include u-padding--large--ends;}
 .u-padding--large--sides  { @include u-padding--large--sides;}

 .u-padding                { @include u-padding;}
 .u-padding--top           { @include u-padding--top;}
 .u-padding--right         { @include u-padding--right;}
 .u-padding--bottom        { @include u-padding--bottom;}
 .u-padding--left          { @include u-padding--left;}
 .u-padding--ends          { @include u-padding--ends;}
 .u-padding--sides         { @include u-padding--sides;}

 .u-padding--small         { @include u-padding--small;}
 .u-padding--small--top    { @include u-padding--small--top;}
 .u-padding--small--right  { @include u-padding--small--right;}
 .u-padding--small--bottom { @include u-padding--small--bottom;}
 .u-padding--small--left   { @include u-padding--small--left;}
 .u-padding--small--ends   { @include u-padding--small--ends;}
 .u-padding--small--sides  { @include u-padding--small--sides;}

 .u-padding--smallest--left {@include u-padding--smallest}

 .u-padding--none          { @include u-padding--none;}
 .u-padding--none--top     { @include u-padding--none--top;}
 .u-padding--none--right   { @include u-padding--none--right;}
 .u-padding--none--bottom  { @include u-padding--none--bottom;}
 .u-padding--none--left    { @include u-padding--none--left;}
 .u-padding--none--ends    { @include u-padding--none--ends;}
 .u-padding--none--sides   { @include u-padding--none--sides;}
