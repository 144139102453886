$luxity-letter-spacing-size: 1.3px;
$luxity-button-dimension: 35px;
$disabled-button-opacity: 0.4;

@mixin button--base {
    @include soft--smallest;
    @include u-bg--button-states($clr-base, $clr-base-lt, $clr-base-ltr);
    border-style: none;
    text-transform: uppercase;
    font-size: $fs-h6;
    color: $clr-ntrl-min;

    &:disabled,
    &[disabled] {
      cursor: not-allowed;
      opacity: $disabled-button-opacity;
    }
}
.button--base {
  @include button--base;
  &:hover {
    color: $clr-ntrl-min;
  }
}

@mixin button--primary {
    @include soft--smallest;
    @include u-bg--button-states($clr-primary, $clr-primary-lt, $clr-primary-ltr);
    border-style: none;
    text-transform: uppercase;
    font-size: $fs-h6;
    color: $clr-ntrl-min;
}

@mixin button--luxity {
  @include soft--smallest--sides;
  @include soft--smaller--ends;
  @include u-bg--luxity-button-states($clr-primary, $clr-ntrl-min, $clr-primary);
  letter-spacing: $luxity-letter-spacing-size;
  border-style: none;
  text-transform: uppercase;
  font-size: $fs-milli;
  color: $clr-ntrl-min;

  &:hover {
    text-decoration: none;
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: $disabled-button-opacity;
  }
}


@mixin button--luxity--circle {
  border: 2px solid $clr-primary;
  color: $clr-ntrl-min;
  border-radius: 50%;
  width: $luxity-button-dimension;
  height: $luxity-button-dimension;
  line-height: $luxity-button-dimension;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.2s ease-in-out;
  background-color: $clr-ntrl-min;
  .icon {
    font-size: $fs-h5;
  }
  path {
    transition: 0.2s ease-in-out;
    fill: $clr-primary;
  }
  &:hover,
  &:focus {
    background-color: $clr-primary;
    path {
      fill: $clr-ntrl-min;
      stroke: $clr-ntrl-min;
    }
  }
}
