
%footer-tooltip-button {
  min-width: 10px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

button {
  &.tooltip-button {
    border-color: transparent;
    border-style: none;
    border-width: 0;
    padding: 0;
    font-size: 1em;
  }
  &.header-tooltip-button {
    background-color: transparent;
    color: $clr-brand;
    @extend .tooltip-button;
    @extend %footer-tooltip-button;
  }
  &.footer-tooltip-button {
    @extend .btn--primary;
    @extend %footer-tooltip-button;
  }
  &.footer-tooltip-button--secondary {
    @extend .btn--secondary;
    @extend %footer-tooltip-button;
  }
}
