.dashboard__header {

}
    .dashboard__header__wrapper {
      display: flex;
      flex-flow: row nowrap;
    }
        .dashboard__header__wrapper__avatar {
          @include push--smallest--right;
          flex-basis: 50px;
          display: flex;
          justify-content: center;
        }
        .dashboard__header__wrapper__action-wrapper {
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;

            & .edit-profile-wrapper {
              @include text--prominent;
              display: flex;
              flex-direction: column !important;
              flex-flow: nowrap row;
              & > div {
                display: flex;
              }
            }

            & .edit-profile-wrapper__profile-name {
              @include push--smallest--right;
              line-height: 1em;
              font-family: $font-el-messiri, sans-serif !important;
            }

            & .logout-button-wrapper button {
              @extend %fc--base-ltr;
              @extend .dashboard__button;
              @include text--prominent;
              @include flush;
              @include hard;

            }
        }


@include media-query(portable){
  .dashboard__header__wrapper {
    display: none;
  }
}
