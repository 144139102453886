.page-review {

  .luxity-radio-buttons-container {
    flex-flow: column;
    align-items: flex-start;
    @include push--large--bottom;
  }

  .luxity-radio-buttons-container__title {
    @include push--small--bottom;
  }

  .luxity-radio-buttons-container__list {
  }

  .luxity-radio-buttons-container__list__item__text {
    line-height: 1.5em
  }

  .luxity-form__item__label {
    @extend .luxity-radio-buttons-container__title;
    display: block;
  }
  .form__item__textarea {
    border: 1px solid $clr-base;
  }

  .luxity-form__wrapping {
    @include u-margin--bottom;
  }
}
