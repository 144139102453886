.mobile-side-menu {
}

.mobile-side-menu__overlay {

}

.mobile-side-menu__content {
  @include soft--small;
  overflow-y: scroll;
}

.mobile-side-menu__content__item {

}


.mobile-side-menu__content__footer {

}

.mobile-side-menu__content__footer__content {
  @include soft--small;
  background-color: $clr-brand;
  text-transform: uppercase;

  a {
    text-decoration: none;
    font-weight: bold;
    color: $clr-ntrl-min;
  }
}

.mobile-side-menu--active {
  .mobile-side-menu__content {

  }

  .mobile-side-menu__overlay {
  }
}



.mobile-side-menu__content__item__title {
  @extend .text-button;
  @extend .flex--align-items-center-nowrap;
  @extend .u-padding--small--bottom;
  font-weight: bold;
  text-transform: capitalize;

  &:focus,
  &:hover {
    color: $clr-base;
  }

  .st0 {
    fill: $clr-light-gray;
  }
}

.mobile-side-menu__content__item__title--link {
  @extend .mobile-side-menu__content__item__title;
  &:focus,
  &:hover {
    color: $clr-primary;
  }
}

.mobile-side-menu__content__item__link {
  @extend .text-button;
  @extend .u-margin--sides;
  text-transform: capitalize;
}
.custom-navbar__content {
  @include media-query(portable){
    .nav-logo {
      margin-bottom: 5px;
    }

    .custom-navbar__content__first-col {
      align-items: flex-end;
      justify-content: center;
    }
  }
}

.nav-search-icon {
  @include media-query(portable){
    display: none;
  }
}

.actions-wrapper__cart-search {
  .cart-icon__count-container {
    display: none;
  }
}
