
/*------------------------------------*\
    $LAYOUT FLEX
\*------------------------------------*/

.fl-vh-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
}

.fl-h-center {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
}

.fl-v-center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
}

.fl-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-flow: row wrap;

  & > .fl-right__item {
    @include push--smaller--right;

    &:last-child {
      @include flush--right;
    }
  }
}

.fl-row-justify-left {
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
}

.fl-col-justify-left {
  display: flex;
  justify-content: flex-start;
  flex-flow: column nowrap;
}

.fl-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;

  & > .fl-left__item {
    @include soft--smaller--right;

    &:last-child {
      @include hard--right;
    }
  }
}

%fl-space {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
}
.fl-space-between {
  @extend %fl-space;
  justify-content: space-between;
};

.fl-space-around {
  @extend %fl-space;
  justify-content: space-around;
}
.fl-align-bottom {
  align-items: flex-end;
}
