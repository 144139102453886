
/*------------------------------------*\
    $ASIDE SLIDE
\*------------------------------------*/


.aside-slide {
  // transition: all 350ms ease-in-out;;
  position: relative;
  overflow-x: hidden;
  z-index: 100;
  width: 100%;
  height: 100%;

  .aside-slide & {
    background-color: transparent;
  }

  &.aside-slide--bgc-alt {
    & > .aside-slide__inner {
      background-color: $clr-base-ltst;
    }
  }

  &.is-open {
    // transition: all 350ms ease-in-out;;
    // transform: translateX(-100%);
  }
}
  .aside-slide__icon {
    @include soft--smallest;
    height: 100%;
    display: block;

    & svg {
      width: 2rem;
      height: 2rem;
    }
  }

  .aside-slide__clickable-area {
    display: none;
    height: 100%;

    & .aside-slide__clickable-area__button {
      display: block;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }

  .aside-slide__inner { // actually a child of ...__header__item
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    display: inline-block;
  }

    .aside-slide__inner__header {
      // @extend %wrap-base;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      @include push--smaller--top;
    }

    %base__title__font {
      // @extend %ff--base;
    }

      %aside-slide__inner__header__item {
        // @extend %base__title__font;
        color: $clr-base-dkst;
        line-height: 1;
        @include font-size($fs-h2);
        @include push--right;

        & %btn--reset {
          display: block;
          height: 100%;
        }
      }

      .aside-slide__inner__header__item { @extend %aside-slide__inner__header__item;}
      .aside-slide__inner__header__item--lt {
        @extend %aside-slide__inner__header__item;
        color: $clr-base-ltr;
      }
      .aside-slide__inner__header__item--right {
        @extend %aside-slide__inner__header__item;
        position: absolute;
        right: 0;
      }

    .aside-slide__inner__body {
      // @extend %wrap--large;
      // @extend %island--smaller;
      height: 100%;
    }
