.icon-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-family: $font-el-messiri, sans-serif !important;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 0px;

  .st0 {
    fill: $clr-light-gray;
  }

  @include media-query(portable) {
    flex-flow: column nowrap;
  }

  .icon-header__left {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-family: $font-el-messiri, sans-serif !important;

    @include media-query(portable) {
      align-self: flex-start;
    }
  }

  .icon-header__right {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
}
