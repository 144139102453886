/*------------------------------------*\
    $LAYOUT CONTAINER
\*------------------------------------*/

.layout-container {
  margin-left: auto;
  margin-right: auto;
  width: 960px;
  position: relative;

  @include media-query(portable){
    width: 100%;
  }

}



@media only screen and (min-width: 1200px) {
    .layout-container{
      width: 1020px;
    }
}

    // .layout-container {
    //   background-color: $clr-base-ltst-alt;
    //   box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.35);
    // }
    //   .layout-container__header {
    //     @include u-padding--small--top;
    //     @include u-padding--small--right;
    //     @include u-padding--small--left;
    //     text-align: center;
    //     color: $clr-base-dk;
    //   }
    //     .layout-container__header__lt-section {
    //       display: block;
    //       @include font-size($fs-h1);
    //       font-weight: 200;
    //     }

    //     %container-text__standard {
    //       @include push--bottom;
    //       @include font-size($fs-h5);
    //       display: block;
    //     }

    //     .layout-container__header__subtitle-section {
    //       @extend %container-text__standard;
    //     }

    //   .layout-container__body {
    //     position: relative;
    //     @include push--largest--bottom;
    //   }

    //     .layout-container__body__seperated {
    //       border-left: 1px solid $clr-base-lt;
    //       height: 100%;
    //       @include soft--large--left;
    //       @include push--small--bottom;
    //     }

    //       .layout-container__body__seperated__title {
    //         display: block;
    //         @include font-size($fs-milli);
    //         @include push--smaller--bottom;
    //       }

    //       .layout-container__body__seperated__inner {
    //         background-color: $clr-base-ltr;
    //         display: block;
    //         font-weight: 300;
    //         @include soft--smaller--ends;
    //         @include soft--small--sides;
    //       }

    //       .layout-container__body-section {
    //         @extend %container-text__standard;
    //       }

    //       .layout-container__body__section--flexed {
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: space-between;
    //         height: 100%;
    //       }

    //   .layout-container__footer {
    //     text-align: right;
    //     text-decoration: underline;
    //     @include font-size($fs-milli);
    //   }

    //     .layout-container__footer__item {
    //       display: inline-block;
    //       @include soft--smaller--sides;
    //     }
