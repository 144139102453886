// !important because react-select is shit
/**
Example Structure (with className="react-select" defined)
<div class="react-select">
  <div class="react-select__control">
    <div class="react-select__value-container">...</div>
    <div class="react-select__indicators">...</div>
  </div>
  <div class="react-select__menu">
    <div class="react-select__menu-list">
      <div class="react-select__option">...</div>
    </div>
  </div>
</div>
*/

.react-select {
  @include push--smaller--bottom;

  .react-select__control {
    border-color: $border-color;
    background-color: $clr-ntrl-min!important;
    border-radius: 0;
    min-height: 2em;

    &.react-select__control--is-focused {
      border-color: $border-color;
      border-radius: 0;
      box-shadow: none;
    }
    & > .react-select__value-container{
      padding: .5em .5em;
      & > div {
        color: $clr-base;
      }
    }

    .react-select__indicator {
      padding: .5em;
    }

  }

  .react-select__menu {
    @include u-margin--none;
    border-radius: 0;

    & > .react-select__menu-list {
      @include u-padding--none;

      & > .react-select__option {
        padding: .5em;
        font-weight: bold;
        border-bottom: solid 1px $clr-ntrl-10;

        &:last-child {
          border-bottom: none;
        }

        &.react-select__option--is-focused {
          background-color: $clr-ntrl-10;
        }
        &.react-select__option--is-selected {
          color: $clr-primary;
          background-color: $clr-ntrl-min;
        }
        &.react-select__option--is-selected.react-select__option--is-focused {
          background-color: $clr-ntrl-10;
        }
      }
    }
  }
}

