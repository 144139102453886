
/*------------------------------------*\
    $LINKS
\*------------------------------------*/


  %link--transition { @include u-link-transition;}

  a {
    @extend %link--transition;
    @extend %fc--base;
    text-decoration: none;

    &,
    &:hover { outline: 0;}
    &:hover {
      text-decoration: underline;
    }

    &:visited {}
  }
