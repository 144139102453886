@mixin pointer-length($divider) {
  width: calc(100% / #{$divider});
}

/*Gauge vars*/
$gauge-margin-left: -18px;

/*Gauge Point var*/
$gauge-point-height: 12px;
$gauge-point-width: 12px;
$gauge-point-border-radius: 50%;

/*Gauge Horizontal Line vars*/
$gauge-line-thickness: 2px;
$gauge-line-position-top: 5px;

%step__gauge__pointer {
  list-style-type: none;
  float: left;
  position: relative;
  text-align: center;

  &:before {
    content: "";
    @include u-margin--smaller--bottom;
    border-radius: $gauge-point-border-radius;
    width: $gauge-point-width;
    height: $gauge-point-height;
    border: 1px solid $clr-base;
    display: block;
    text-align: center;
    margin: 0 auto;
    background-color: $clr-ntrl-min;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: $gauge-line-thickness;
    background-color: $clr-base;
    top: $gauge-line-position-top;
    left: -50%;
    z-index: -1;
  }

  &:first-child:after {
    content: none;
  }
}

.step-1-gauge,
.step-2-gauge,
.step-3-gauge,
.step-4-gauge,
.step-5-gauge,
.step-6-gauge,
.step-7-gauge {
  width: 100%;
  margin-left: $gauge-margin-left;
  &__pointer {
    @extend %step__gauge__pointer;

    &--active {
      @extend %step__gauge__pointer;
      color: $clr-brand;

      &:before {
        border-color: $clr-brand;
        background-color: $clr-brand;
      }

      &:after {
        background-color: $clr-brand;
      }
    }
  }
}

@for $i from 1 through 7 {
  .step-#{$i}-gauge__pointer, .step-#{$i}-gauge__pointer--active {
    @include pointer-length($i);
  }
}


