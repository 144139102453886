/*------------------------------------*\
  $CODE
\*------------------------------------*/
/**
 * Use an explicit font stack to ensure browsers render correct `line-height`.
 */
pre { overflow:auto;}

  pre mark {
    background:none;
    border-bottom:1px solid;
    color:inherit;
  }
