.generic-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 900;
  background-color: rgba(26, 26, 26, 0.75);
  overflow-y: auto;

  &__content {
    @include u-padding;
    color: $clr-ntrl-min;
    font-weight: bold;
    font-size: $fs-h3;
    position: sticky;
    top: calc(50% - #{$fs-h3});
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
  }

  &__description {
      font-weight: normal;
  }
}
