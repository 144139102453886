.luxity-mobile-side-menu {
  .mobile-side-menu {
    .mobile-side-menu__overlay {
      top: 0;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.5s ease-out 0s, visibility 0.5s ease-out 0s;
    }

    .mobile-side-menu__content {
      transition: left 0.5s ease-in-out;
    }
  }

  .mobile-side-menu--active {
    .mobile-side-menu__overlay {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.5s ease-out 0s, visibility 0.5s ease-out 0s;
    }

    .mobile-side-menu__content {
      transition: left 0.5s ease-in-out;
    }
  }
}

.luxity-mobile-side-menu {
  font-size: 14px;
  .luxity-mobile-side-menu__content-section{
    .mobile-side-menu__content__item__link {
      @include soft--small--left;
      width: 100%;
    }
  }
}
