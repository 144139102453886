.sales {

}
    .sales__footer {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
    }
      .sales__footer__item {
        flex-basis: 100%;
      }

