
.form__item {
    display: flex;
    flex-flow: column;

    &--horizontal {
        @include push--smaller--bottom;
        display: inline-flex;
        flex-flow: row wrap;
        width: 100%;

        &.has-error {
          @extend .form__item--horizontal;

          .form__item__input {
            border: 1px solid $clr-brand;
          }
        }

        .form__item__label {
          align-items: center;
            display: flex;
            flex-basis: 40%;
        }
        .form__item__input {
            flex-basis: 60%;
        }
    }

    &--horizontal--hidden {
      display: none;
    }
}

.form__item__validation {
    @extend .form__item__validation;
    @include push--smaller--top;
    text-align: right;
}


@include media-query(portable){
  .form__item--horizontal {
    flex-flow: row wrap;
  }
  .form__item__label, .form__item__input {
    flex-basis: 100% !important;
  }
}
