/*------------------------------------*\
    ELEMENT HEIGHT UTILITIES
\*------------------------------------*/
/*Setting custom width for elements in percentage
  e.g u-percentage-width-50 will be 50% width
*/
@for $i from 1 through 100 {
    .u-percentage-height-#{$i} {
      height: #{$i+'%'};
    }
  }
  