 .app-layout__header__top {
    background: #4e4949;
    color: $clr-ntrl-min;
    font-size: $fs-milli;
    text-align: center;
  }

  .app-layout__header__middle {
    border-bottom: 1px solid $border-color;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  %app-layout__header__middle__icon {
    @include f8-btn;
    display: none;
    position: absolute;
    align-items: center;
    height: 100%;
    top: 0;
    bottom: 0;
  }

  .app-layout__header__middle__burger-menu {
    @extend %app-layout__header__middle__icon;
    @include push--smallest--left;
    left: 0;
  }


  .app-layout__header__middle__profile-avatar {
    @extend %app-layout__header__middle__icon;
    @include push--smallest--right;
    right: 0;
  }

  .app-layout__header__middle__logo {
    @include push--small--ends;
    text-align: center;
  }



  .app-layout__header__menu {
    border-bottom: 1px solid $border-color;
  }


  @include media-query(portable){
    .app-layout__header__top {
      display: none;
    }

    .app-layout__header__middle {
      .app-layout__header__middle__burger-menu,
      .app-layout__header__middle__profile-avatar {
        display: flex;
        margin-right: 0;
      }
    }
  }
