$payment-radio-button-radius: 10px;
$payment-radio-button-height: 40px;

$outer-circle-height: 20px;
$inner-circle-height: 10px;


.payment-radio-button {
  label {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
  }
  border-radius: $payment-radio-button-radius;
  transition: 0.2s ease-in-out;
  @include u-margin--smaller--ends;
  height: $payment-radio-button-height;
  border: solid $clr-ntrl-min--transp-dkst 1px;
  input[type="radio"] {
    visibility: hidden;
  }

  &__outer-circle {
    height: $outer-circle-height;
    width: $outer-circle-height;
    border-radius: 50%;
    border: 1px solid $clr-brand;
    display: flex;
    align-items: center;
    @include  u-margin--small--left;

    &__inner {
      margin-left: auto;
      margin-right: auto;
      transition: 0.2s ease-in-out;
      height: $inner-circle-height;
      width: $inner-circle-height;
      border-radius: 50%;
      background-color: $clr-ntrl-min--transp-dkst;
    }
  }

  &:hover, &--active {
    @extend .payment-radio-button;
    border: solid $clr-brand 1px;
    color: $clr-brand;

    .payment-radio-button__outer-circle__inner {
      background-color: $clr-brand;
    }
  }
}
