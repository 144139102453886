.dropdown-selector-container{
  width: 100%;

  .dropdown-selector__control{
    min-height: 30px;
    border-radius: 0;

    &--is-focused{
      border-radius: 0;
    }

    &--menu-is-open{
      border-radius: 0;
    }
  }
  .dropdown-selector__indicator{
    padding: 0;
  }
  .dropdown-selector__value-container{
    padding: 0;
  }
}
