/*------------------------------------*\
    $LISTS
\*------------------------------------*/
/**
 * Remove vertical spacing from nested lists.
 */
li {
  & > ul,
  & > ol {
    margin-top: 0;
    margin-bottom: 0;
  }
}
