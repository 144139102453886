$item-summary-line-height: 1;
$order-status-tooltip-width: 300px;

.item {
  @include border--container;

  h6 {
    line-height: 20px;
  }

  &__generic-title {
    text-transform: uppercase;
    font-weight: bold;
  }
}


    .item__header {
        @include soft--large--bottom;
        @include push--large--bottom;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        border-bottom: 1px solid $border-color;

      @include media-query(portable){
        flex-flow: row wrap;
      }

    }
        .item__header__left {
            flex-basis: 50%;
          @include media-query(portable){
            flex-basis: 100%;
          }

        }
            .item__header__left__primary {
                @include text--prominent;
                @include push--smallest--bottom;
            }
            .item__header__left__secondary {

            }

        .item__header__right {
            flex-basis: 50%;
          @include media-query(portable){
            flex-basis: 100%;
          }
        }
            .item__header__right__primary {
              @include text--prominent;
              @include push--smallest--bottom;
            }

            .item__header__right__primary--status-container {
              @extend .item__header__right__primary;
              .tooltip__body {
                @include soft--small;
                font-size: $fs-base;
                font-weight: initial;
                width: $order-status-tooltip-width;
                left: calc(50% - #{$order-status-tooltip-width} / 2);
              }
            }

            .item__header__right__secondary {

            }
    .item__details {

    }
        .item__details__title {

        }

        // <div className="orders__item__summary item__summary">
        // <div className="item__summary__item">
        //   <div className="item__summary__item__left">Subtotal:</div>
        //   <div className="item__summary__item__right">
        //     {data.order_subtotal}
        //   </div>
        // </div>

    .item__summary {
      @include soft--top;
      border-top: 1px solid $border-color;
    }
        .item__summary__item {
          @include push--small--bottom;
          line-height: $item-summary-line-height;
          display: flex;
          flex-flow: row nowrap;
        }
        .item__summary__item--payment-row {
          @extend .item__summary__item;
          @include media-query(palm){
            display: block;

            .orders__item__payment__button {
              text-align: left;
            }

          }
        }
            .item__summary__item__left {
                flex-basis: 50%;
            }
            .item__summary__item__right {
                font-weight: bold;
                text-align: right;
                flex-basis: 50%;
            }
              .item__summary__item__right--list {
                @extend .item__summary__item__right;
                & > div, & > p {
                  @include push--small--bottom;
                }
              }

.item-summary-desktop-container {
  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.item__summary__mobile__payment {
  display: flex;
  flex-flow: row;
}

.item-summary-mobile-container {
  display: none;
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;

    .orders__item__payment__button {
      margin-top: 0;
    }

    .btn--icon--left {
      margin-top: 0;
    }

    @media only screen and (max-width: 424px) {
      display: flex;
      flex-direction: column;
        .orders__item__payment__button {
          margin-top: 0;
          .btn--luxity {
            font-size: 10px;
          }
        }
    }


  }
}

