/*------------------------------------*\
    $TOOLTIPS
\*------------------------------------*/

/*Usage:
<div class="status-tooltip">Hover over me
  <span class="status-tooltip__body">Tooltip text</span>
</div>
*/

$tooltip-border-radius: 5px;
$tooltip-width: 120px;

$tooltip-arrow-border-width: 5px;


%relative-inline {
  position: relative;
  display: inline-block;
}

//top middle by default
.tooltip {
  @extend %relative-inline;
  cursor: pointer;
}

.tooltip__body {
  @extend %relative-inline;
  @include u-padding--smaller--ends;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0.3s linear;
  background-color: $clr-base;
  color: $clr-base-ltst-alt;
  width: $tooltip-width;
  text-align: center;
  border-radius: $tooltip-border-radius;
  /*Position tooltip at top middle*/
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: calc(50% - #{$tooltip-width} / 2);

  &::after {
    /*Bottom Arrow*/
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: calc(50% - #{$tooltip-arrow-border-width});
    border-width: $tooltip-arrow-border-width;
    border-style: solid;
    border-color: $clr-base transparent transparent transparent;
  }
}

.tooltip:hover .tooltip__body {
  opacity: 1;
  visibility: visible;
}

// bottom middle
.tooltip--bottom-middle {
  @extend .tooltip;

  .tooltip__body {
    bottom: initial;
    top: 100%;

    &::after {
      content: " ";
      position: absolute;
      bottom: 100%;  /* At the top of the tooltip */
      top: initial;
      left: 50%;
      border-style: solid;
      border-color: transparent transparent $clr-base transparent;
    }
  }
}





