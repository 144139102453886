// <div className="login u-padding">
// <div className="login__body">
//   <div className="login__body__wrapper fl-row-justify-left">
//     <div className="login__body__wrapper__form fl-col-justify-left">
//       <LoginForm />
//     </div>
//     <div className="login__body__wrapper__image">
//       <img src="/images/legs.png" alt="Luxity" />
//     </div>
//   </div>
// </div>
// </div>

$wrapper-image-height: 120px;

.login{
  width: 100%;
}

  .login__body{

  }
    .login__body__wrapper {
      display: flex;
      flex-flow: row nowrap;
    }


      .login__body__wrapper__form {
        @include soft--larger;
        flex-basis: 50%;
      }

      .login__body__wrapper__image {
        flex-basis: 50%;
        img {
          height: 100%;
          display: block;
        }
      }


@include media-query(portable){
  .login__body__wrapper {
    flex-flow: row wrap;
  }

  .login__body__wrapper__form, .login__body__wrapper__image {
    flex-basis: 100%;

    img {
      width: 100%;
      height: $wrapper-image-height;
      object-fit: cover;
    }
  }

}
