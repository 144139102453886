/*------------------------------------*\
    $FORMS
\*------------------------------------*/
label { cursor: pointer;}

textarea { resize: vertical;}

button, input, select, textarea { vertical-align: baseline;}

input, input[type='search'], textarea { border-radius: 0;}

input, textarea {
  border: 1px solid $border-color;
  height: auto;         // don't apply heights set to inputs
  line-height: normal;  // all browsers must use FF's UA !important for consistency
  padding: .5em;
  width: 100%;
  font-size: 1em;
}

input, textarea:focus {
  outline: none;
}

input[type="radio"],
input[type="checkbox"] { width: auto;}

input[type="password"] { letter-spacing: .3em;} // space password to match actual text
input[type="password"]::placeholder { letter-spacing: 0;}

.field { @include u-margin--bottom;}
