/*------------------------------------*\
  $FORMS
\*------------------------------------*/
/**
 *
 * Demo: jsfiddle.net/inuitcss/MhHHU
 *
 */
fieldset { padding: $base-spacing-unit;}


/**
 * Radios and Checkboxes
 *
 * Make radios and checkboxes appear clickable
 */
input[type="radio"],
input[type="checkbox"] {
	cursor: pointer;
	border: none;
}
