%address__item {
  @include push--bottom;
  .address-accordion {
    .address-accordion__toggle-button__title {
      color: $clr-base;
    }
  }
}
.address__form__group{
  .address__form__group__item {
    &--add{
      .address__item {
        @extend %address__item;
        .address-accordion {
          .address-accordion__toggle-button__title {
            color: $clr-brand;
          }
        }
      }
    }
    .address__item {
      @extend %address__item;

      .address-icon {
        font-size: $fs-h4;
      }
    }
  }
}

// Shoe Form Labels when in view Mode
.address-form {
  &.editing{
    .form__item--horizontal {
      label{
        display: none;
      }
    }
    .province-country__dropdown-selector-wrapper__title{
      display: none;
    }
  }
}

.address-form {
  width: 100%;

  .section__fields__wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .form__item--horizontal {
    display: inline-block;
    flex: 1 1 100%;
    padding: 3px;
    margin: 0 0 6px;

    label{
      display: block;
    }

    .form__item__input {
      flex-basis: initial;
    }

    .form__item__line1, .form__item__line2{

    }
    &.form__item__city, &.form__item__state, &.form__item__postcode {
      flex: 1 1 25%;
    }

    .province-country__dropdown-selector-wrapper {
      display: flex;
      flex-flow: column nowrap;
      .province-country__dropdown-selector-wrapper__title {
        align-self: initial;
      }
    }
  }

  .form__item__label,
  .form__item__input {
    height: $field-height;
    display:flex;
    align-items: center;
  }


  .form__item__label {
    padding-top: 0;
    height: auto;
  }
}

.address-title {
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 20em;

  @include media-query(palm){
    width: 8em;
  }
}

.address-title-component {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .address-title-componen__icon-title {
    display: flex;
    align-items: center;
  }

  .trash-address-icon {
    margin-right: 15px;
    padding-right: 0;

    svg {
      height: 1em;
      fill: $clr-brand;
    }
  }
}

.uncheked-mark-icon{
  opacity: 0.1;
}

.form__item--hidden {
  display: none;
}

.switch-address-type {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.switch-address-type .type-label {
  font-size: 14px;
  margin-right: 15px;
  input {
    margin-right: 10px;
  }
}

.company_name {
  min-height: 40px;
}

.vat_number {
  min-height: 40px;
}
