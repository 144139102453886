/*------------------------------------*\
    COLORS
\*------------------------------------*/
$clr-brand: #000000;
$clr-brand-2: #333333;
$clr-selection: $clr-brand;

$base-ui-color: #777777;
$brand-color: #004d91;
$selection-color: $brand-color;

$border-color: #d8d8d8;
$clr-light-gray: #efefef;
$clr-store-title: #555555;

$clr-coupon-background: #F3EEEC;
$clr-sale-void-disabled: #555555;

$clr-error-message: #ff0000;


/**
 * The base color for text, borders, etc.
 */
$clr-base      : #4E4949;
$clr-base-lt   : lighten($clr-base, 20%);
$clr-base-ltr  : lighten($clr-base, 35%);
$clr-base-ltst : lighten($clr-base, 40%);
$clr-base-ltst-alt  : #fafafa;

$clr-base-dk   : darken($clr-base, 10%);
$clr-base-dkr   : darken($clr-base, 20%);
$clr-base-dkst   : darken($clr-base, 35%);


$clr-base--transp--dk  : transparentize($clr-base-dk, 0.2);
$clr-base--transp--base--50  : transparentize($clr-base, 0.5);
$clr-base--transp--lt--10  : transparentize($clr-base-lt, 0.1);
$clr-base--transp--lt--30  : transparentize($clr-base-lt, 0.3);
$clr-base--transp--lt--50  : transparentize($clr-base-lt, 0.5);
$clr-base--transp--lt--70  : transparentize($clr-base-lt, 0.7);
$clr-base--transp--lt--90 : transparentize($clr-base-lt, 0.9);

/**
 * The primary non-base color
 */
$clr-primary      : $clr-brand;
$clr-primary-lt   : lighten($clr-primary, 20%);
$clr-primary-ltr  : lighten($clr-primary, 35%);
$clr-primary-ltst : lighten($clr-primary, 40%);
$clr-primary-dk   : darken($clr-primary, 10%);

$clr-secondary      : $clr-brand-2;
$clr-secondary-lt   : lighten($clr-secondary, 20%);
$clr-secondary-ltr  : lighten($clr-secondary, 35%);
$clr-secondary-ltst : lighten($clr-secondary, 40%);
$clr-secondary-dk   : darken($clr-secondary, 10%);


// neutrals
$clr-ntrl-min : #fff;                          // white
$clr-ntrl-5  : darken($clr-ntrl-min, 5%);
$clr-ntrl-10  : darken($clr-ntrl-min, 10%);
$clr-ntrl-20  : darken($clr-ntrl-min, 20%);
$clr-ntrl-50  : darken($clr-ntrl-min, 50%);
$clr-ntrl-80  : darken($clr-ntrl-min, 80%);
$clr-ntrl-90  : darken($clr-ntrl-min, 90%);
$clr-ntrl-max : darken($clr-ntrl-min, 100%);   // black

$clr-ntrl-min--transp-dkst : transparentize($clr-ntrl-min, 0);
$clr-ntrl-min--transp-dkr : transparentize($clr-ntrl-min, 0.05);
$clr-ntrl-min--transp-dk : transparentize($clr-ntrl-min, 0.35);
$clr-ntrl-min--transp     : transparentize($clr-ntrl-min, 0.2);
$clr-ntrl-min--transp-lt  : transparentize($clr-ntrl-min, 0.4);
$clr-ntrl-min--transp-ltr : transparentize($clr-ntrl-min, 0.75);
$clr-ntrl-max--transp : transparentize($clr-ntrl-max, 0.5);
$clr-ntrl-max--transp-lt  : transparentize($clr-ntrl-max, 0.7);
$clr-ntrl-max--transp-ltr  : transparentize($clr-ntrl-max, 0.8);

// success colours
$clr-success      : #468847;
$clr-success-lt   : lighten($clr-success, 20%);
$clr-success-ltr  : lighten($clr-success, 45%);
$clr-success-ltst : lighten($clr-success, 50%);
$clr-success-dk   : darken($clr-success, 10%);

// warning colours
$clr-warning       : #ffcb6b;
$clr-warning-lt    : lighten($clr-warning, 20%);
$clr-warning-ltr   : lighten($clr-warning, 35%);
$clr-warning-ltst  : lighten($clr-warning, 40%);
$clr-warning-dk    : darken($clr-warning, 10%);

// danger / error / important colours
$clr-danger      : $clr-error-message;
$clr-danger-lt   : lighten($clr-danger, 20%);
$clr-danger-ltr  : lighten($clr-danger, 35%);
$clr-danger-ltst : lighten($clr-danger, 40%);
$clr-danger-dk   : darken($clr-danger, 10%);

// info colours
$clr-info      : #3a87ad;
$clr-info-lt   : lighten($clr-info, 20%);
$clr-info-ltr  : lighten($clr-info, 40%);
$clr-info-ltst : lighten($clr-info, 45%);
$clr-info-dk   : darken($clr-info, 10%);

// log profile circle colours
$clr-profile-circle-student : #C09853;
$clr-profile-circle-linemanager : #9099C2;
$clr-profile-circle-supervisor : #AF1E2d;
$clr-profile-circle-ato_rep : #D28089;
$clr-profile-circle-assessor : #0F3B5F;
$clr-profile-circle-wbl_admin : #813775;
