/**
 * Create a fully formed type style (sizing and vertical rhythm) by passing in a
 * single value, e.g.:
 *
   `@include font-size(10px);`
 *
 * Thanks to @redclov3r for the `line-height` Sass:
 * twitter.com/redclov3r/status/250301539321798657
 */
@use "sass:math";

@mixin font-size($font-size, $line-height:false, $px-fallback:false){

  @if unitless($font-size){
    @include px-and-rem(font-size, $font-size);
  } @else {
		@if ($px-fallback) { font-size: $font-size;}
    font-size:math.div($font-size, $fs-base) * 1rem;
  }

  @if $line-height == true {
    line-height:ceil(math.div($font-size, $base-line-height)) * math.div($base-line-height, $font-size);
  }
}

