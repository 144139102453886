/*------------------------------------*\
  $TABLES
\*------------------------------------*/
table { width:100%;}

th, td {
  text-align:left;
  padding: #{$base-spacing-unit * 0.25}rem;

  @media screen and (min-width:480px){
    padding: #{$base-spacing-unit * 0.5}rem;
  }
}
