.dashboard {
  @include soft--top;
  @include soft--sides;
  @include soft--bottom;
}
    .dashboard__header {
        @include push--bottom;
    }
        .dashboard__button {
          @include f8-btn;
          @extend .u-margin--ends;
          font-size: $fs-milli;
        }

    .dashboard__body{

    }
        .dashboard__body__wrapper {

        }
            .dashboard__body__wrapper__sidebar {
              width: 27.5%;
            }
            .dashboard__body__wrapper__content {
                @include push--large--left;
                width: 72.5%;
            }

@include media-query(portable){
  .dashboard {
    @include soft--sides;
    @include soft--ends;

  }

  .dashboard__header {
    margin-bottom: 0;
  }

  .dashboard__body__wrapper__sidebar {
    display: none;
  }
  .dashboard__body__wrapper__content {
    margin-left: 0;
    width: 100%;
  }
}

