/*------------------------------------*\
    $DOCUMENT
\*------------------------------------*/
/**
 * This section houses styles for the base elements of the page, generally body and
 * html
 */


/**
 * Fix fatty fonts in Chrome and Firefox on Mac:
 * http://maximilianhoffmann.com/posts/better-font-rendering-on-osx
 */
%fix-fatty-fonts {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
 * Use sticky footer:
 * http://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/
 *
 * requires flex: 1 0 auto; on primary content
 */
%sticky-footer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  /**
   * don't use sticky footer on IE10 >=
   *
   * just don't bother
   */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block;
  }
}

%full-body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  & > div {
    flex-grow: 1;
  }
}

/**
 * Prevent Chrome and Safari from flashing when a user taps on mobile
 */
@if $responsive {
  html { -webkit-tap-highlight-color: transparent;}
}

html, body, button, input, select, textarea {
  // @extend %fix-fatty-fonts;
}

body {
  @extend %full-body;
  background-color: $clr-ntrl-min;
  min-width: 960px; // #150
  color: $clr-brand;
  @include media-query(portable){
    min-width: 100%;
  }
}




/**
 * get 60fps scrolling by disabling pointer events:
 * http://www.thecssninja.com/javascript/pointer-events-60fps
 */
.disable-hover,
.disable-hover * { pointer-events: none !important;}
