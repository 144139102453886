@mixin border--container {
  @include soft--larger;
  @include soft--top;
  @include soft--large--bottom;
  @include push--large--bottom;
  border: 1px solid $border-color;
  width: 100%;
}

@mixin border--container--highlighted {
  transition: 0.2s ease-in-out;
  @include border--container;
  border: 1px solid $clr-brand;
}
