// <div className="orders__item item">
// <div className="item__header">
//   <div className="item__header__left">
//     <div className="item__header__left__primary">
//       Order No. {data.order_number}
//     </div>
//     <div className="item__header__left__secondary">
//       Order Date: {data.order_date}
//     </div>
//   </div>
//   <div className="item__header__right">
//     <div className="item__header__right__primary">
//       Order Status: {data.order_status}
//     </div>
//     <div className="item__header__right__secondary">
//       <div className="status-graph">{data.order_status}/5</div>
//     </div>
//   </div>
// </div>
// <div className="item__details">
//   <h3 className="item__details__title">Your Order</h3>
//   <div className="item__details__sub-items sub-items">
//     {data.items.map(item => (
//       <div className="sub-items__item">
//         <div className="sub-items__item__image">
//           <img src={item.image} alt="item.name" />
//         </div>
//         <div className="sub-items__item__details">
//           <div className="sub-items__item__details__name">
//             {item.name}
//           </div>
//           <div className="sub-items__item__details__price">
//             Item Price: <strong>{item.price}</strong>
//           </div>
//         </div>
//       </div>
//     ))}
//   </div>
// </div>
// <div className="orders__item__summary item__summary">
//   <div className="item__summary__item orders__item__summary__invoice">
//     <div className="item__summary__item__left">&nbsp;</div>
//     <div className="item__summary__item__right orders__item__invoice__button">
//       <a href="{data.invoice_link}">DOWNLOAD INVOICE</a>
//     </div>
//   </div>

.orders {
    @include push--bottom;
}
    .orders__item {

    }
        .orders__item__details {

        }
        .orders__item__summary {
        }
            .orders__item__summary__invoice {
                @include soft--top;
            }
            .orders__item__invoice__button {
              font-weight: bold;
            }
            .orders__item__payment__button {
              @include push--top;
              align-self: center;
            }

    .orders__footer {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
    }
      .orders__footer__item {
        flex-basis: 100%;
      }


