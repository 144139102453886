.flex {
  display: flex;
  &--justify-content-between {
    @extend .flex;
    justify-content: space-between;
  }
  
  &--align-items-center-wrap {
    @extend .flex;
    align-items: center;
    flex-flow: row wrap;
  }

  &--align-items-center-nowrap {
    @extend .flex;
    align-items: center;
    flex-flow: row nowrap;
  }
}
