.form__item__contact-number {
  @extend .horizontal-list;

  & .form__item__contact-number__dial-code{
    flex-grow: 1;
    flex-basis: 15%;
  }

  & .form__item__contact-number__separator{
    display: flex;
    flex-basis: 9px;
  }

  & .form__item__contact-number__national{
    display: flex;
    flex-grow: 5;
    & .form__item__input{
      flex-basis: 100%;
    }
  }
}

