$logo-max-width-mobile: 120px;

%svg {
  font-size: 2rem;
  @include u-padding--smaller;
}

.svg {
  @extend %svg;
  &--avatar {
    font-size: 4rem;
  }
}

.svg--brand {
  @extend %svg;
  .icon__inner {
    svg {
      color: $clr-brand;
      & path {
        fill: $clr-brand;
      }
    }
  }
}

.svg--hard-left {
  @extend %svg;
  @include hard--left;
}

.svg--logo {
  .icon__inner {
    svg {
      height: initial;
      width: initial;
    }
  }
}

@include media-query(portable){
  .svg--logo {
    .icon__inner {
      svg {
        max-width: $logo-max-width-mobile;
      }
    }
  }
}
