/*------------------------------------*\
    $BUTTONS
\*------------------------------------*/
button { @include u-link-transition;}

%btn {
  @extend %btn !optional;
  @include u-link-transition;
  color: $clr-ntrl-min;
  line-height: 1;

  &[disabled],
  &:disabled {

    &,
    &:hover,
    &:focus,
    &:active {

    }
  }
}

.btn { @extend %btn;}

// %btn-style {
//   @extend %btn;
//   @include font-size($fs-h6);
//   padding: 1.1em 2em;
//   border-radius: 5px;
//   min-width: 140px;
//   text-align: center;
// }

// %btn--large {
//   @extend %btn;
//   width: 100%;
//   padding: 1em 2em;
// }

// .btn--text {
//   @extend %btn--reset;
//   @include font-size($fs-h6);
//   vertical-align: top;
//   line-height: inherit;

//   &:focus,
//   &:hover {
//     color: $clr-base-lt;
//   }

//   &:active {
//     color: $clr-base-dk;
//   }
// }

%btn--text--base {
  @extend %btn--reset;
  @extend %fc--base;
  vertical-align: top;
  line-height: inherit;
}
.btn--text--base { @extend %btn--text--base;}
.btn--text--base-dk {
  @extend %btn--text--base;
  @extend %fc--base-dk;
}

%btn--text--primary {
  @extend %btn--reset;
  @extend %fc--primary;
  vertical-align: top;
  line-height: inherit;
}

.btn--text--primary { @extend %btn--text--primary;}
.btn--text--primary {
  & span.icon {
    width: 2rem;
    height: 1.2rem;
  }
}

// .btn--text--primary--large {
//   @extend %btn--text--primary;
//   @include font-size($fs-h4);
// }

// .btn--text--secondary {
//   @extend %btn--reset;
//   @extend %fc--secondary;
//   vertical-align: top;
//   line-height: inherit;
// }

// .btn--text--ntrl-min {
//   @extend %btn--reset;
//   @extend %fc--ntrl-min;
//   vertical-align: top;
//   line-height: inherit;
// }

// .btn--text[disabled] {
//   opacity: 0.5;
// }

// %btn--inverse {
//   @extend %btn-style;
//   position: relative;
//   background: transparent;

//   &:after {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     border: 2px solid $clr-base;
//     border-radius: 5px;
//   }

//   &:focus,
//   &:hover {
//     background-color: $clr-base;
//     color: $clr-ntrl-min;
//   }

//   &:active {
//     background-color: $clr-base-dk;
//   }
// }

// .btn--inverse--primary {
//   @extend %btn--inverse;
//   color: $clr-primary;

//   &:after {
//     border-color: $clr-primary;
//   }

//   &:focus,
//   &:hover {
//     background-color: $clr-primary;
//   }

//   &:active {
//     background-color: $clr-primary-dk;
//   }
// }

// .btn--inverse--secondary {
//   @extend %btn--inverse;
//   color: $clr-secondary;

//   &:after {
//     border-color: $clr-secondary;
//   }

//   &:focus,
//   &:hover {
//     background-color: $clr-secondary;
//   }

//   &:active {
//     background-color: $clr-secondary-dk;
//   }
// }

// // .btn--inverse--quinary {
// //   @extend %btn--inverse;
// //   color: $clr-quinary;

// //   &:after {
// //     border-color: $clr-quinary;
// //   }

// //   &:focus,
// //   &:hover {
// //     background-color: $clr-quinary;
// //   }

// //   &:active {
// //     background-color: $clr-quinary-dk;
// //   }
// // }

// .btn--inverse--base-lt {
//   @extend %btn--inverse;
//   color: $clr-base;
//   border-color: $clr-base;
//   min-width: 4rem;
//   line-height: 1;
//   vertical-align: middle;

//   &:focus,
//   &:hover {
//     background-color: $clr-base-ltst;
//     color: $clr-base-dk;
//   }

//   &:active {
//     color: $clr-base-dkst;
//     background-color: $clr-base-ltr;
//   }
// }


// %btn--primary {
//   @extend %btn-style;
//   background-color: $clr-primary;

//   &:hover,
//   &:focus { background-color: $clr-primary-lt;}

//   &:active { background-color: $clr-primary-dk;}
// }
// .btn--primary { @extend %btn--primary;}
// .btn--primary--disabled {
//   @extend .btn--primary;
//   pointer-events: none;
// }

// // %btn--quinary {
// //   @extend %btn-style;
// //   background-color: $clr-quinary;

// //   &:hover,
// //   &:focus { background-color: $clr-quinary-lt;}

// //   &:active { background-color: $clr-quinary-dk;}
// // }
// // .btn--quinary { @extend %btn--quinary;}

// //   .btn--quinary--disabled {
// //     @extend %btn--quinary;
// //     pointer-events: none;
// //     background-color: $clr-quinary-ltr;
// //   }


// %btn--secondary {
//   @extend %btn-style;
//   background-color: $clr-secondary;
//   color: $clr-ntrl-min;
//   // padding: 0.3em 0.5em;

//   &:hover,
//   &:focus {
//     background-color: $clr-secondary-lt;
//     color: $clr-ntrl-min;
//   }

//   &:active {
//     background-color: $clr-secondary-dk;
//     color: $clr-ntrl-min;
//   }
// }
// .btn--secondary { @extend %btn--secondary;}

// %btn--danger {
//   @extend %btn-style;
//   background-color: $clr-danger;

//   &:hover,
//   &:focus { background-color: $clr-danger-lt;}

//   &:active { background-color: $clr-danger-dk;}
// }
// .btn--danger { @extend %btn--danger;}

// /**
//   CANCEL BUTTON
// **/
// %btn--base--transp{
//   @extend %btn-style;
//   background-color: transparent;
//   color: $clr-base;

//   &:hover,
//   &:focus {
//     color: $clr-base-ltr;}

//   &:active { color: $clr-base-ltst;}
// }
// .btn--base--transp { @extend %btn--base--transp;}


// %btn--base--large {
//   @extend %btn--large;
//   background-color: $clr-base-ltr;
//   color: $clr-base-dkr;
//   font-weight: bold;

//   &:hover,
//   &:focus {
//     background-color: $clr-base-ltst;
//   }

//   &:active {
//     background-color: $clr-base-ltr;
//   }
// }

// .btn--base--large { @extend %btn--base--large; }
// %btn--base-dk--large {
//   @extend %btn--base--large;
//   background-color: $clr-base-lt;

//   &:hover,
//   &:focus {
//     background-color: $clr-base-ltr;
//   }

//   &:active {
//     background-color: $clr-base-ltr;
//   }
// }

// .btn--base--large--inverse {
//   @extend %btn--base--large;
//   background-color: $clr-ntrl-min;
// }

// .btn--disabled {
//   pointer-events: none!important;
// }
