/*------------------------------------*\
    $SVG COLOR MODIFIERS
\*------------------------------------*/
%svg--base { color: $clr-base;}
a%svg--base,
button%svg--base {
  @include u-svg--link-states(
    $clr-base,
    $clr-base-lt,
    $clr-base-dk
  );
}
.svg--base { @extend %svg--base;}

%svg--base-lt { color: $clr-base-lt;}
a%svg--base-lt,
button%svg--base-lt {
  @include u-svg--link-states(
    $clr-base-lt,
    $clr-base-ltr,
    $clr-base
  );
}
.svg--base-lt { @extend %svg--base-lt;}

%svg--base-ltr { color: $clr-base-ltr;}
a%svg--base-ltr,
button%svg--base-ltr {
  @include u-svg--link-states(
    $clr-base-ltr,
    $clr-base-ltst,
    $clr-base-lt
  );
}
.svg--base-ltr { @extend %svg--base-ltr;}

%svg--base-dkst { color: $clr-base-dkst;}
/* create accompannying things? */

%svg--base-dk { color: $clr-base-dk;}
a%svg--base-dk,
button%svg--base-dk {
  @include u-svg--link-states(
    $clr-base-dk,
    $clr-base,
    $clr-base-dkr
  );
}
.svg--base-dk { @extend %svg--base-dk;}

%svg--primary { color: $clr-primary;}
a%svg--primary,
button%svg--primary {
  @include u-svg--link-states(
    $clr-primary,
    $clr-primary-lt,
    $clr-primary-dk
  );
}
.svg--primary { @extend %svg--primary;}

%svg--ntrl-min { color: $clr-ntrl-min;}
a%svg--ntrl-min,
button%svg--ntrl-min {
  @include u-svg--link-states(
    $clr-ntrl-min,
    $clr-ntrl-10,
    $clr-ntrl-20
  );
}
.svg--ntrl-min { @extend %svg--ntrl-min;}

%svg--danger { color: $clr-danger;}
a%svg--danger,
button%svg--danger {
  @include u-svg--link-states(
    $clr-danger,
    $clr-danger-lt,
    $clr-danger-dk
  );
}
.svg--danger { @extend %svg--danger;}

%svg--success { color: $clr-success;}
a%svg--success,
button%svg--success {
  @include u-svg--link-states(
    $clr-success,
    $clr-success-lt,
    $clr-success-dk
  );
}
.svg--success { @extend %svg--success;}

%svg--warning { color: $clr-warning;}
a%svg--warning,
button%svg--warning {
  @include u-svg--link-states(
    $clr-warning,
    $clr-warning-lt,
    $clr-warning-dk
  );
}
.svg--warning { @extend %svg--warning;}
