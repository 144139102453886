/*------------------------------------*\
    FONT COLOR UTILITIES
\*------------------------------------*/
// .u-fc--base { color: $clr-base;}
// a.u-fc--base,
// button.u-fc--base {
//   @include fc--link-states(
//     $clr-base,
//     $clr-base-lt,
//     $clr-base-dk
//   );
// }

.u-fc--primary { color: $clr-brand;}
.u-fc--danger { color: $clr-danger;}
.u-fc--warning { color: $clr-warning;}
.u-fc--white { color: $clr-ntrl-min;}
// a.u-fc--primary,
// button.u-fc--primary {
//   @include fc--link-states(
//     $clr-primary,
//     $clr-primary-lt,
//     $clr-primary-dk
//   );
// }


/*------------------------------------*\
    $FONT COLOR MODIFIERS
\*------------------------------------*/
%fc--base { color: $clr-base;}
a%fc--base,
button%fc--base {
  @include fc--link-states(
    $clr-base,
    $clr-primary,
    $clr-base-dk
  );
}
.fc--base { @extend %fc--base;}

%fc--base-lt { color: $clr-base-lt;}
a%fc--base-lt,
button%fc--base-lt {
  @include fc--link-states(
    $clr-base-lt,
    $clr-base-ltr,
    $clr-base
  );
}
.fc--base-lt { @extend %fc--base-lt;}

%fc--base-ltr { color: $clr-base-ltr;}
a%fc--base-ltr,
button%fc--base-ltr {
  @include fc--link-states(
    $clr-base-ltst,
    $clr-base-lt,
    $clr-base-lt
  );
}
.fc--base-ltr { @extend %fc--base-ltr;}

%fc--base-dkst { color: $clr-base-dkst;}
/* create accompannying things? */

%fc--base-dk { color: $clr-base-dk;}
a%fc--base-dk,
button%fc--base-dk {
  @include fc--link-states(
    $clr-base-dk,
    $clr-base,
    $clr-base-dkr
  );
}
.fc--base-dk { @extend %fc--base-dk;}

%fc--primary { color: $clr-primary;}
a%fc--primary,
button%fc--primary {
  @include fc--link-states(
    $clr-primary,
    $clr-primary-lt,
    $clr-primary-dk
  );
}
.fc--primary { @extend %fc--primary;}

%fc--secondary { color: $clr-secondary;}
a%fc--secondary,
button%fc--secondary {
  @include fc--link-states(
    $clr-secondary,
    $clr-secondary-lt,
    $clr-secondary-dk
  );
}
.fc--secondary { @extend %fc--secondary;}

%fc--ntrl-min { color: $clr-ntrl-min;}
a%fc--ntrl-min,
button%fc--ntrl-min {
  @include fc--link-states(
    $clr-ntrl-min,
    $clr-ntrl-10,
    $clr-ntrl-20
  );
}
.fc--ntrl-min { @extend %fc--ntrl-min;}

%fc--danger { color: $clr-danger;}
a%fc--danger,
button%fc--danger {
  @include fc--link-states(
    $clr-danger,
    $clr-danger-lt,
    $clr-danger-dk
  );
}
.fc--danger { @extend %fc--danger;}
