/*------------------------------------*\
    FONT SIZE MODIFIER UTILITIES
\*------------------------------------*/

.text-x-large {
  font-size: x-large;
}

.text-larger {
  font-size: larger;
}
.text-small {
  font-size: small;
}

.text-smaller {
  font-size: smaller;
}
