/*------------------------------------*\
		$SHARED
\*------------------------------------*/
/**
 * Where `margin-bottom` is concerned, this value will be the same as the
 * base line-height. This allows us to keep a consistent vertical rhythm.
 * As per: csswizardry.com/2012/06/single-direction-margin-declarations
 */
/**
 * Base elements
 */
h1,h2,h3,h4,h5,h6,hgroup,
ul,ol,dl,
blockquote,p,address,
table,
fieldset,figure,
pre {
	margin-top: 0;
  margin-bottom: px-to-rem($fs-base * $base-spacing-unit);
}



/**
 * `hr` elements only take up a few pixels, so we need to give them special
 * treatment regarding vertical rhythm.
 */
hr { margin-bottom: px-to-rem($fs-base * $base-spacing-unit);}


/**
 * Where `margin-left` is concerned we want to try and indent certain elements
 * by a consistent amount. Define that amount once, here.
 */
ul,ol,dd { margin-left: 1.5em;}
