$outer-circle-dimension: 20px;
$circle-border-radius: 50%;
$outer-circle-width: 1px;
$inner-circle-dimension: 10px;

.luxity-radio-buttons-container {
  display: flex;
  align-items: center;
}

.luxity-radio-buttons-container__title {
  font-weight: bold;
  color: $clr-base-lt;
  flex-basis: 40%;
}

.luxity-radio-buttons-container__list {
  display: flex;
  flex-grow: 1;
}

.luxity-radio-buttons-container__list__item {
  @include push--smallest--bottom;
  @include push--smallest--right;
  display: flex;
}

.luxity-radio-buttons-container__list__content {

}

.luxity-radio-buttons-container__list__item__circle {
  height: $outer-circle-dimension;
  width: $outer-circle-dimension;
  border-radius: $circle-border-radius;
  border: $outer-circle-width solid $clr-base;
  display: flex;

}

.luxity-radio-buttons-container__list__item__circle__inner {
  height: $inner-circle-dimension;
  width: $inner-circle-dimension;
  border-radius: $circle-border-radius;
  margin: auto;
}


.luxity-radio-buttons-container__list__item__input {
  display: none;
}

.luxity-radio-buttons-container__list__item__input:checked ~ .luxity-radio-buttons-container__list__item__circle {
  .luxity-radio-buttons-container__list__item__circle__inner {
    background-color: $clr-base;
  }
}

.luxity-radio-buttons-container__list__item__text {
  @include push--smallest--left;
}



.luxity-radio-buttons-container__list--no-wrap {
  @extend .luxity-radio-buttons-container__list;
  flex-direction: row;
  flex-flow: row nowrap;
}

.luxity-radio-buttons-error-msg {
  color: $clr-primary;
  font-size: $fs-h6;
}

@include media-query(portable){
  .luxity-radio-buttons-container {
    display: block;
  }
}






