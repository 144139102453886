.page {
  @include soft--largest--top;
  @include soft--sides;
  @include soft--bottom;

}
    .page__header {
        @include push--bottom;
    }
    .page_body{

    }
