$action-dimension: 43px;
$items-buttom-border-margin: 7px;

.sale-action-button {
    @include push--smallest--left;
    border: 1px solid $border-color;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $action-dimension;
    height: $action-dimension;
    min-width: $action-dimension;
    min-height: $action-dimension;
    a {
      @include u-svg--link-states($clr-primary, $clr-primary, $clr-base);
      display: flex;
    }
}
.latest-sale-desktop-container {
  display: flex;
  flex-direction: column;
  .sale-headings {
    display: flex;
    .sale-heading-child {
      flex-basis: 20%;
    }
    .empty-space {
      width: 58px;
    }
  }
  .sale-content-container {
    display: flex;
    margin-bottom: 7px;
    .sale-content-values {
      display: flex;
      width: 100%;
      align-items: center;
      border: 1px solid $border-color;
      .sale-content-child {
        flex-basis: 20%;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.latest-sale-mobile-container {
  display: none;
  margin-bottom: 20px;
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    border: 1px solid $border-color;
    padding: 20px;

    .latest-sale-mobile-container__first-row {
      display: flex;
      width: 100%;
      .latest-sale-mobile-container__first-row__col-1 {
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
      }
      .latest-sale-mobile-container__first-row__col-2 {
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
        .sales-content {
          display: flex;
          width: 100%;
          @media only screen and (max-width: 424px) {
            flex-direction: column;
          }
          strong {
            flex-basis: 80%;
            text-align: right;
          }
          .sales-content-value {
            flex-basis: 50%;
            margin-left: 20px;
            align-self: flex-end;
            text-align: left;
          }
        }
      }
    }
    .latest-sale-mobile-container__second-row {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      .action-button {
        width: 135px;
      }
    }
  }
}
