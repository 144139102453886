.mobile-menu-overlay {
  position: fixed;
  left: 0;
  bottom: -100vh;
  height: 100%;
  width: 100%;
  z-index: 99;
  background-color: $clr-base--transp--dk;
  animation: menu-overlay-slide 0.5s forwards;
}

@keyframes menu-overlay-slide {
  100% { bottom: 0; }
}
