.aside-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  background-color: $clr-ntrl-5;
}

.aside-wrapper__child {
  width: 100%;
  height: 100%;
  position: absolute;

  &--half {
    width: 100%;
    order: 1;
    flex: 1 50%;
    position: relative;
    overflow-y: auto;
  }
  &--half_right {
    overflow-y: auto;
    width: 100%;
    position: relative;
    order: 2;
    flex: 1 50%;
    animation: slide 300ms;
    margin-left: 1.5rem;
  }
}
