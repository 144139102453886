/*------------------------------------*\
    $BASE
\*------------------------------------*/
i { font-style: normal;}
u { text-decoration: underline;}

blockquote {
  font-style: italic;
  @include font-size($fs-h3);
  @include u-padding;
}

kbd {
  border: 1px solid;
  border-radius: 2px;
  font-size: 85%;
  padding: .25em .5em;
  vertical-align: middle;
}

hr {
  border: 1px solid;
  border-left: 0;
  border-right: 0;
  border-top: 0;
}
