%form__item {
  // @include u-margin--bottom;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
}

.form__item { @extend %form__item;}
  .form__item--danger {
    @extend %form__item;

    .form__item__input, .form__item__textarea {
      &::placeholder{
        color: $clr-ntrl-min;
      }
      background-color: $clr-danger-ltr;
      border: 1px solid $clr-danger-lt;
      @include push--smallest--bottom;
    }
  }

  .form__item__validation {
    width: 100%;
    color: $clr-danger;
    font-size: $fs-h6;
    font-weight: lighter;
    display: block;
    line-height: 1;
  }


  %form__item__label {
    @include font-size($fs-base);
    color: $clr-base-lt;
    font-weight: lighter;
    display: block;
    line-height: 1.2em;
  }
  .form__item__label { @extend %form__item__label;}
