$field-height: 40px;

.province-country {

  .province-country__title {
    text-transform: uppercase;
    @include push--small--bottom;
  }

  .province-country__dropdown-selector-wrapper {
    display: flex;
    flex-flow: row nowrap;

    .province-country__dropdown-selector-wrapper__title {
      color: $clr-base-lt;
      align-self: center;
      flex-basis: 40%;
    }


    .dropdown-selector-container {
      flex-grow: 1;
    }

    .dropdown-selector__control {
      border-radius: 0;
      height: $field-height;
      width: 100%;
    }

    .dropdown-selector__control--is-disabled {
      background-color: transparent;
      border: transparent;

      .dropdown-selector__indicators {
        display: none;
      }
      .dropdown-selector__value-container--has-value {
        padding-left: 0;
      }

      .dropdown-selector__single-value--is-disabled {
        color: $clr-base-dk;
      }
    }
  }

  .form__item__input:disabled {
    background-color: transparent;
    border: transparent;
  }

  .province-country__dropdown-selector-wrapper--error {
    @extend .province-country__dropdown-selector-wrapper;
    .dropdown-selector__control {
      border-color: $clr-primary;
      &:hover,
      &:focus {
        border-color: $clr-primary;
      }
    }
  }

  .province-country__validation {
    @extend .form__item__validation;
  }

  @include media-query(portable) {
    .province-country__dropdown-selector-wrapper,
    .form__item--horizontal
    {
      display: block;
    }
  }

}
