/**
 * Media query mixin.
 *
 * It’s not great practice to define solid breakpoints up-front, preferring to
 * modify your design when it needs it, rather than assuming you’ll want a
 * change at ‘mobile’. However, as inuit.css is required to take a hands off
 * approach to design decisions, this is the closest we can get to baked-in
 * responsiveness. It’s flexible enough to allow you to set your own breakpoints
 * but solid enough to be frameworkified.
 *
 * We define some broad breakpoints in our vars file that are picked up here
 * for use in a simple media query mixin. Our options are:
 *
 * palm
 * lap
 * lap-and-up
 * portable
 * desk
 * desk-wide
 *
 * Not using a media query will, naturally, serve styles to all devices.
 *
 * `@include media-query(palm){ [styles here] }`
 *
 * We work out your end points for you:
 */
$palm-end:        $lap-start - 1px;
$lap-end:         $desk-start - 1px;

@mixin media-query($media-query){

  @if $media-query == palm{

    @media only screen and (max-width:$palm-end) { @content; }

  }

  @if $media-query == lap{

    @media only screen and (min-width:$lap-start) and (max-width:$lap-end) { @content; }

  }

  @if $media-query == lap-and-up{

    @media only screen and (min-width:$lap-start) { @content; }

  }

  @if $media-query == portable{

    @media only screen and (max-width:$lap-end) { @content; }

  }

  @if $media-query == desk{

    @media only screen and (min-width:$desk-start) { @content; }

  }

  @if $media-query == desk-wide{

    @media only screen and (min-width: $desk-wide-start) { @content; }

  }

  @if $media-query == hi-res {

    @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
    only screen and (-o-min-device-pixel-ratio: 3/2),
    only screen and (min--moz-device-pixel-ratio: 1.5),
    only screen and (min-device-pixel-ratio: 1.5) {
      @content;
    }

  }

}
