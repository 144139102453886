.popup-modal__container {
}

.popup-modal__header {
  @include u-padding--top;
  @include u-padding--right;
  @include u-padding--left;
  @include u-padding--small--bottom;
  @include font-size($fs-h3);
  text-align: center;
  font-weight: bold;
  color: $clr-base-dk;
}

.popup-modal__body {
  @include u-padding--right;
  @include u-padding--left;
  white-space: pre-line;
  background-color: $clr-ntrl-min;
}
