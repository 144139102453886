$notification-height: 40px;
$notification-font-size: 1rem;

.luxity-notification {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-basis: 95%;
  padding: 1rem 2rem;
  margin-bottom: 2rem;

    &__wrapper{
      @include u-padding--none;
      max-width: 100%;
      min-height: $notification-height;
      display: flex;
      flex-grow: 1;

    }

    &__content {
      flex-basis: 90%;
      flex-grow: 1;
      text-align: start;
      line-height: 1rem;
      font-size: $notification-font-size;
      align-self: center;
    }

    &__close {
      display: flex;

      &__button {
        @extend %btn;
        @include u-padding--smaller;
        @include u-bgc--link-states(
            transparent,
            transparent,
            transparent
        );
        flex-basis: 10%;
        align-self: center;
        color: inherit;
        line-height: 1rem;
      }
    }

    .svg-block {
      display: inherit;
    }

    .icon {
      flex-basis: 5%;
      align-self: center;
      margin-right: 2rem;
      &__inner svg {
        .st0 {
          fill: $clr-ntrl-min;
        }
      }
    }
}
