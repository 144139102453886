/*------------------------------------*\
    $BUTTONS
\*------------------------------------*/


button,
div[role="button"] {
  outline: 0;
  -webkit-tap-highlight-color: transparent !important;
}

button { @include u-link-transition;}

.text-button {
  background-color: transparent;
  border: none;
  transition: 0.4s;
  text-transform: uppercase;
  &:hover {
    color: $clr-brand;
  }

  &:disabled,
  &[disabled] {
    text-transform: uppercase;
    border: none;
    cursor: not-allowed;
    opacity: $disabled-button-opacity;
  }
}

.button--base, .btn--base {
  @include button--base;
}

.btn--light {
  @include button--base;
  @include u-bg--button-states($clr-ntrl-min,$clr-ntrl-min,$clr-ntrl-min,);
  color: $clr-base;
  border: 1px solid $border-color;
  &:hover {
    color: $clr-brand;
  }
}

.btn--base--info {
  @include button--base;
  @include u-bg--button-states($clr-info, $clr-info-lt, $clr-info-ltr);
}

.btn--base--warning {
  @include button--base;
  @include u-bg--button-states($clr-warning, $clr-warning-lt, $clr-warning-ltr);
}

%btn {
  @include f8-btn;
  @include u-link-transition;
  color: $clr-ntrl-min;

  &[disabled],
  &:disabled {
    &,
    &:hover,
    &:focus,
    &:active {

    }
  }
}

%text-btn {
  @extend %btn;
  @extend .text-button;
  @include font-size($fs-h6);
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 0.3em;
  padding-top: 0.3em;
  border-radius: 5px;
  min-width: 200px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  vertical-align: text-bottom;
  line-height: 1.5em;
}

%svg-btn {
  @extend %btn;
}

.svg-btn--base-lt {
  @extend %svg-btn;
  font-size: $fs-base;
  background-color: transparent;
  transition: 0.3s ease-in-out;
  @include u-svg--link-states(
    $clr-base-lt,
    $clr-base-ltr,
    $clr-base-ltst
  );
}
.svg-btn--base-dk {
  @extend %svg-btn;
  font-size: $fs-base;
  background-color: transparent;
  transition: 0.3s ease-in-out;
  @include u-svg--link-states(
    $clr-base-dk,
    $clr-base,
    $clr-base-dkr
  );
}

.svg-btn--base-dk--red {
  @extend  .svg-btn--base-dk;
  @include u-svg--link-states(
                  $clr-danger,
                  $clr-danger-lt,
                  $clr-danger-dk
  );
}

.svg-btn--base-dk--rotated {
  @extend .svg-btn--base-dk;
  transform: rotate(180deg);
}

.btn--primary {
  @include button--primary;
}


.btn--luxity {
  @include button--luxity;
}

.btn--luxity--circle {
  @include button--luxity--circle;
}

.btn--luxity--circle--centered {
  @extend .btn--luxity--circle;
  margin-left: auto;
  margin-right: auto;
}

.btn--primary--borderless-right {
  @extend .btn--primary;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn--primary--borderless-left {
  @extend .btn--primary;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn--primary--large {
  @extend .btn--primary;
  @include soft--smaller--sides;
  width: auto;
}

.btn--secondary {
  @extend %text-btn;

  @include u-bgc--link-states(
    $clr-secondary,
    $clr-secondary-lt,
    $clr-secondary-dk
  );
}

.btn--secondary--borderless-right {
  @extend .btn--secondary;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn--secondary--borderless-left {
  @extend .btn--secondary;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.cancel-order-btn {
  @extend .text-button;
  @extend %fc--base;
  text-decoration: none;
  padding: 6px 0 0 0;
  text-transform: none;
  &,
  &:hover { outline: 0;}
  &:hover {
    text-decoration: underline;
  }
}

.btn--icon--left {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  &.inv-download {
    margin-top: 0;
  }
  .svg {
    padding: 0;
  }
}
