/*------------------------------------*\
    $TYPOGRAPHIC LINK STATES MIXIN
\*------------------------------------*/
@mixin fc--link-states($base-clr, $hover-clr, $active-clr) {
  color: $base-clr;

  &:hover { color: $hover-clr;}

  @media (hover) {
    &:focus { color: $hover-clr;}
  }

  &:active { color: $active-clr;}
}

@mixin u-bg--button-states($base-clr, $hover-clr, $active-clr) {
  background-color: $base-clr;

  &:hover { background-color: $hover-clr;}

  &:focus,
  &:active { background-color: $active-clr;}
}

@mixin u-bg--luxity-button-states($base-clr, $hover-clr, $active-clr) {
  background-color: $base-clr;

  &:hover,
  &:active { background-color: $hover-clr; color: $active-clr}
  &:focus { background-color: $base-clr; color: $hover-clr}

}

@mixin u-svg--link-states($base-clr, $hover-clr, $active-clr) {
  & path {
    fill: $base-clr;
  }

  &:hover {
    & path {
      fill: $hover-clr;
    }
  }

  @media (hover) {
    &:focus {
      & path {
        background-color: $hover-clr;
      }
    }
  }

  &:active {
    & path {
      background-color: $active-clr;
    }
  }
}
