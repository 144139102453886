/*------------------------------------*\
    $MODAL
\*------------------------------------*/

%modal {
  transition: opacity 350ms ease-in-out,
              top 0ms ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $clr-base--transp--base--50;
  opacity: 1;
  z-index: 99999;
  overflow-y: scroll;
  &.is-closed {
    transition: opacity 350ms ease-in-out,
                top 0ms ease-in-out 350ms;
    top: -100%;
    left: 0;
    opacity: 0;
  }
}
.modal {@extend %modal;}

  .modal--large {
    @extend %modal;

    & > .modal__inner {
      min-width: 75vw;
      min-height: 75vh;
    }
  }

  .modal--small {
    @extend %modal;

    & > .modal__inner {
      min-width: 50vw;
      min-height: 50vh;
    }
  }

  .modal__inner {
    // @include u-padding--small--sides;
    // @include u-padding--small--ends;
    padding:0;
    background-color: $clr-ntrl-min;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translateX(-50%) translateY(-50%);
    min-height: 15vh;
    min-width: 35vw;
    border-radius: 10px;
    box-shadow: 0px 0px 10px $clr-ntrl-max--transp-ltr;

    @media only screen and (max-width: 600px) {
      width: 90%;
      .simple-popup__header {
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-size: 1.5rem;
      }

      .simple-popup__footer {
        .modal__container__footer__item__button--secondary-button {
          min-width: 0;
        }
      }
    }
  }

    .modal__close {
      // position: absolute;
      text-align: right;
      right: 0;
      top: 0;
    }

    .modal__container {
      position: relative;
    }

    .modal__container__footer {
      text-align: center;
      display: flex;
      flex-direction: row;

      & > .modal__container__footer__item {
        & > button {
          border-radius: 0 0 0 0;
        }
        &:first-child {
          & > button {
            border-bottom-left-radius: 10px;
          }
        }
        &:last-child {
          & > button {
            border-bottom-right-radius: 10px;
          }
        }
      }
    }

      .modal__container__footer__item {
        flex: 1;
        min-height: 40px;
      }

        .modal__container__footer__item__button {
          width: 100%;
          height: 100%;

          &:disabled,
          &[disabled] {
            opacity: $disabled-button-opacity;
            cursor: not-allowed;
          }
        }


        .modal__container__footer__item__button--primary-button {
          @extend .btn--primary;
          @extend .modal__container__footer__item__button;
        }
          .modal__container__footer__item__button--primary-button--bold {
            @extend .modal__container__footer__item__button--primary-button;
            font-weight: bold;
          }

        .modal__container__footer__item__button--secondary-button {
          @extend .btn--secondary;
          @extend .modal__container__footer__item__button;

          &:hover, &:focus {
            color: $clr-ntrl-min;
          }

          &:disabled,
          &[disabled] {
            opacity: $disabled-button-opacity;
            cursor: not-allowed;
          }
        }
