/*------------------------------------*\
    $MENU
\*------------------------------------*/
%menu-container {
  @include media-query(portable) { overflow: hidden;}
}

  %menu {
    display: inline-block;
    @include f8-list--reset;

    @include media-query(portable) { display: block;}
  }

    %menu__item {
      position: relative;
      display: inline-block;

      @include media-query(portable) { display: block;}

      & > a {
        display: block;
        color: $clr-primary;
        line-height: 3;
        outline: 0;
        padding-left: 1em;
        padding-right: 1em;

        &:hover,
        &:focus {}

        &:active {}

        @include media-query(portable) {
          // collapse all links on portable
          @include menu-anchor-collapse();

          // only show items when grandparent has .is-open-menu
          .is-open-menu & {
            max-height: 8em;
            opacity: 1;
            padding-top: .5em;
            padding-bottom: .5em;
          }
        }
      }

      %menu__sub & { display: block;}
    }
    .menu__item {
      @extend %menu__item;
      @include soft--smaller;
    }

    %menu__item--current {
      & > a {
        &,
        &:hover,
        &:focus,
        &:active { cursor: default;}
      }
    }
    .menu__item--current,
    .menu__item--current-parent { @extend %menu__item--current;}

      %menu__sub {
        background-color: #fff;
        max-height: 0;
        overflow: hidden;
        position: absolute;
        @include f8-list--reset;
        @include u-link-transition;

        a:hover + &,
        &:hover {
          border: 1px solid $clr-base;
          max-height: none;
          overflow: visible;
        }

        & a {
          min-width: 225px;
          white-space: nowrap;

          // collapse menu__sub links
          @include menu-anchor-collapse();

          @include media-query(portable) {
            white-space: normal;
            min-width: none;
            padding: 0 1.5em;
          }
        }

        // uncollapse menu__sub links when grandparents are hovered
        a:hover + & > %menu__item a,
        &:hover > %menu__item > a {
          max-height: 8em;
          padding-top: 1em;
          padding-bottom: 1em;
          opacity: 1;
        }

        @include media-query(portable) {
          display: block;
          position: relative;
          margin-left: 1.5em;
          border: 0;
          opacity: 1;
          z-index: 0;
        }
      }
      .menu__sub { @extend %menu__sub;}

        %menu__sub %menu__sub {
          top: 0;
          left: 100%;

          @include media-query(portable) {
            top: auto;
            left: auto;
          }
        }

.brand-choice {
  line-height: 1.5;
}

.address-link {
  line-height: 1;
}

.store-title {
  font-weight: normal;
  font-size: $fs-h5;
  margin-bottom: 10px;
}

.store-clossing-times-title {
  margin-bottom: 0;
  color: $clr-store-title;
}
