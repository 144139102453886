/*------------------------------------*\
    WRAPS
\*------------------------------------*/
/**
 * Generates classes for wraps defined in mixins/components/layout/_wraps.scss
 * e.g.

  .wrap--large { ... }
  .wrap { ... }
  .wrap--small { ... }
 */
@each $wrap-name, $wrap-width in $wrap-widths {
  @if $wrap-name == 'base' {
    .wrap {
      @include wrap-width($wrap-name);
      @include wrap-base;
    }
  } @else {
    .wrap--#{$wrap-name} {
      @include wrap-width($wrap-name);
      @include wrap-base;
    }
  }
}

.wrap--largest {
  max-width: $page-width-largest;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: block;
  padding-left: 10%;
  padding-right: 10%;
}
