/*------------------------------------*\
  $HEADINGS
\*------------------------------------*/
h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  line-height: 1;
  margin-top: 0;
  font-family: $font-el-messiri, sans-serif !important;
  color: $clr-primary;
  letter-spacing: 3px;
}

h3 {
  line-height: 40px;
}
