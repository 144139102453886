/*------------------------------------*\
    $APP LAYOUTS
\*------------------------------------*/
$app-header-height: 192px;

.app-layout {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

  .app-layout__header {
    width: 100%;

    .luxity-wordpress-menu-container__auth-burger-menu {
      @include media-query(portable){
        top: 25px;
      }
    }
  }

  .app-layout__body {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    flex-grow: 1;
  }


@media only screen and (min-width: 1024px) {
  .app-layout {
  }
    .app-layout__header {
      // width: 1020px;

    }

    .app-layout__body {
      max-width: 1250px;

    }



}

