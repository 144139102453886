/*------------------------------------*\
    FOOTER
\*------------------------------------*/
$footer-bg-color: #efefef;
$text-color: #939393;
$links-font-size: 14px;
$title-font-size: 17px;
$line-height: 20px;

.footer-wrap {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @include media-query(portable) {
    padding-left: 0;
    padding-right: 0;
  }
}

.footer-container-wrapper {
  background: $footer-bg-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include media-query(portable) {
    display: none;
  }
}

.footer-container {
  max-width: 1250px;
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  height: 300px;
  width: 100%;
}

.footer-container h4 {
  margin-bottom: 20px;
  font-size: $title-font-size;
}

.footer-container ul {
  margin-top: 10px;
  list-style: none;
}

.footer-container ul li {
  margin-bottom: 15px;
  color: $text-color;
}

.footer-container div {
  display: flex;
  flex-direction: column;
}

.footer-phone {
  color: $text-color;
  font-size: $links-font-size;
}

.footer-container div > a {
  margin-bottom: 10px;
  color: $text-color;
  text-align: left;
  font-size: $links-font-size !important;
  line-height: $line-height;
}

.footer-container div > a:hover {
  color: $clr-brand;
}


.contact-office-hours {
  margin-top: 35px;
  ul {
    margin-left: 0;
    font-size: $links-font-size;
    line-height: $line-height;
  }
}

.footer-company {
  margin-top: 50px;
}

.footer-copyrights {
  max-width: 1250px;
  padding-top: 20px;
  border-top: 1px solid;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.footer-copyrights p {
  font-size: 10px;
}

.footer-copyrights div:nth-child(2) {
  text-align: end;
}

.footer-luxity-logo {
  width: 135px;
  height: 30px;
  svg {
    width: 100% !important;
    height: 100% !important;
    fill: $clr-primary !important;
  }
}

.social{
  i {
    margin-right: 10px;
  }
}


.mobile-footer-wrapper {
  display: none;
  @include media-query(portable) {
    display: flex;
    flex-direction: column;
    background: $footer-bg-color;
    padding-left: 24px;
    padding-top: 30px;
  }
}

.mobile-footer-container {
  @include media-query(portable) {
    .mobile-footer-container-row {
      display: flex;
      justify-content: space-between;
      font-size: $links-font-size;
      .mobile-footer-section {
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
        h4 {
          margin-top: 10px;
          font-size: $title-font-size;
        }
        div {
          display: flex;
          flex-direction: column;
          a {
            margin-bottom: 10px;
            color: $text-color;
          }

          a:hover {
            color: $clr-brand !important;
          }
        }
      }
    }
  }
}

.mobile-footer-container ul {
  list-style: none;
}

.footer-link-container {
  display: flex;
  flex-direction: column;
}

.mobile-gravityform .ginput_container_email {
  color: $text-color;
  text-decoration: bold;
  border: none;
  padding: 8px;
  width: 15em;
  @media only screen and (max-width: 418px) {
    width: 10em;
  }
}
.mobile-copyrights {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mobile-copyrights p {
  font-size: 10px;
  text-align: center;
  padding-right: 20px;
}

.mobile-footer-container > div > div {
  display: flex;
  flex-direction: column;
}

.mobile-footer-container > div > div > a {
  margin-bottom: 10px;
  color: $text-color;
  line-height: $line-height;
}

.mobile-footer-container > div > div > a:hover {
  color: $clr-brand !important;
}

.footer-payment-options p{
  margin-bottom: 10px;
  color: $text-color;
  font-size: 14px;
  line-height: $line-height;
}

.footer-background {
  background-color: $footer-bg-color;
}
