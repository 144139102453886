%horizontal-list {
    display: flex;
    flex-flow: row nowrap;
}
    %horizontal-list__item {
        flex-grow: 1;
    }

.horizontal-list {
    @extend %horizontal-list;
}

  .horizontal-list__item {
    @extend %horizontal-list__item;
  }





