/*------------------------------------*\
  $RULES
\*------------------------------------*/
/**
 * Horizontal rules, extend `hr`.
 *
 * Demo: jsfiddle.net/inuitcss/L6GuZ
 *
 */
@mixin f8-rule {
  color: $clr-base;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: #{$base-spacing-unit}rem;
}


/**
 * Dotted rules
 */
@mixin f8-rule--dotted {
  border-bottom-style: dotted;
}


/**
 * Dashed rules
 */
@mixin f8-rule--dashed {
  border-bottom-style: dashed;
}


/**
 * Ornamental rules. Places a § over the rule.
 */
@mixin f8-rule--ornament {
  position: relative;

  &:after {
    content: "\00A7";
    position: absolute;
    top:   0;
    right: 0;
    left:  0;
    line-height: 0;
    text-align: center;
  }

  /**
  * Pass in an arbitrary ornament though a data attribute, e.g.:
  *
    <hr class="rule  rule--ornament" data-ornament="!">
  *
  */
  &[data-ornament]:after {
    content: attr(data-ornament);
  }
}
