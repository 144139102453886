// <div className="item__details__sub-items sub-items">
// {data.items.map(item => (
//   <div className="sub-items__item">
//     <div className="sub-items__item__image">
//       <img src={item.image} alt="item.name" />
//     </div>
//     <div className="sub-items__item__details">
//       <div className="sub-items__item__details__name">{item.name}</div>
//       <div className="sub-items__item__details__price">
//         Item Price: <strong>{item.price}</strong>
//       </div>
//     </div>
//   </div>
// ))}
// </div>

.sub-items {
    
}
    .sub-items__item {
        @include push--bottom;
        display: flex;
        flex-flow: row nowrap;

        &:last-child {
            @include flush--bottom;
        }
    }
        .sub-items__item__image {
            flex-basis: 30%;
        }
        .sub-items__item__details {
            @include soft--small;
            flex-basis: 70%;
        }
            .sub-items__item__details__item{
                @include push--smaller--bottom;
                &:last-child {
                    @include hard--bottom;
                }
            }

    @include media-query(portable){
      .sub-items__item {
        flex-flow: row wrap;
      }

      .sub-items__item__image, .sub-items__item__details {
        flex-basis: 100%;
      }

    }
