$login-action-container-margin-left: 40%;

.login-form {
    
}

.login-form__action-container {
  margin-left: $login-action-container-margin-left;
  &__button  {
    @include button--base;
    @include u-padding--smaller--ends;
    @include u-padding--large--sides;
  }

  @include media-query(portable){
    margin-left: 0;
  }
}

.login-form__link {
  color: $clr-brand;
}
