/*------------------------------------*\
    $VISIBILITY
\*------------------------------------*/
/**
 * Hide from both screenreaders and browsers: h5bp.com/u
 */
.hidden { display: none; visibility: hidden;}


/**
 * Hide content off-screen without resorting to `display:none;`, also provide
 * breakpoint specific hidden elements.
 */
%visuallyhidden {
  @include accessibility;

  &.focusable:active, &.focusable:focus {
    position: static;
    clip: auto;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
 }
}
.accessibility,
.visuallyhidden { @extend %visuallyhidden;}

@if $responsive {
  @each $state in palm, lap, lap-and-up, portable, desk, desk-wide {
    @include media-query(#{$state}) {
      .accessibility--#{$state},
      .visuallyhidden--#{$state} {
        @include accessibility;
     }
   }
 }
}//endif
