.interests {
  .icon-header__left {
    font-family: $font-el-messiri, sans-serif !important;
    text-transform: uppercase;
    letter-spacing: 3px;
    svg {
      path {
        fill: $clr-brand ;
      }
    }
  }
  .luxity-accordion__toggle-button__title {
    color: $clr-brand;
  }
}
