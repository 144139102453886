/*------------------------------------*\
    $LOADERS
\*------------------------------------*/

.loader {

  .loader__ellipsis {
    height: 1rem;

    .loader__ellipsis__dot {
      background-color: $clr-primary;
      top: inherit;
    }
  }
}


