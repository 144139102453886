/*------------------------------------*\
    $FOOTER
\*------------------------------------*/
.area-footer {}

.footer-container h4 {
  font-family: $font-el-messiri, sans-serif !important;
  color: $clr-primary;
}
.mobile-footer-container h4 {
  font-family: $font-el-messiri sans-serif !important;
}
