.simple-popup {
  display: flex;
  flex-direction: column;
}

  .simple-popup__header {
    @extend .popup-modal__header;
  }


  .simple-popup__body {
    @extend .popup-modal__body;
  }

  .simple-popup__footer {
    @extend .modal__container__footer;
  }


  .simple-popup__footer__item {
    @extend .modal__container__footer__item;
  }
