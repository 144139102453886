.profile {
  .form__item__contact-number {

    .form__item__contact-number__dial-code {
      @include media-query(palm){
        flex-basis: 40%;
      }
    }
  }

  &__account-details {
    .react-select {
      margin-bottom: 0;
    }
    .account-type {
      margin-bottom: 0;
    }
    .form-buttons {
      margin-top: 1em;
    }
  }
}
  .profile__section {

  }
