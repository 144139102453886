.sidebar {
  border: 1px solid $border-color;
}
    .sidebar__group {
      @include soft--larger--sides;
      @include push--small--bottom;

      &:first-child {
        @include push--small--top;
      }

    }
        .sidebar__group__title {
          @include soft--smallest;
          @include text--prominent;
          @include push--small--bottom;
          display: flex;
          align-items: center;
          flex-flow: row nowrap;
          border-bottom: 1px solid $clr-primary;

        }

          .sidebar__group__title__content {
            position: relative;
            display: inline-block;
            margin: 0 auto;
            text-transform: uppercase;
            font-family: $font-el-messiri, sans-serif !important;
            font-weight: bold;
            color: $clr-brand;
          }

        .sidebar__group__items {
            display: flex;
            flex-flow: column;
            & > a {
                @include push--smallest--bottom;
                display: block;
                width: 100%;
                text-align: center;
                text-transform: uppercase;
                &.active {
                  color: $clr-primary;
                  text-decoration: underline;
                }

                &:last-child {
                    @include flush--bottom;
                }
            }
        }
