/*------------------------------------*\
    MARGIN UTILITIES
\*------------------------------------*/
 .u-margin--large         { @include u-margin--large;}
 .u-margin--large--top    { @include u-margin--large--top;}
 .u-margin--large--right  { @include u-margin--large--right;}
 .u-margin--large--bottom { @include u-margin--large--bottom;}
 .u-margin--large--left   { @include u-margin--large--left;}
 .u-margin--large--ends   { @include u-margin--large--ends;}
 .u-margin--large--sides  { @include u-margin--large--sides;}

 .u-margin                { @include u-margin;}
 .u-margin--top           { @include u-margin--top;}
 .u-margin--right         { @include u-margin--right;}
 .u-margin--bottom        { @include u-margin--bottom;}
 .u-margin--left          { @include u-margin--left;}
 .u-margin--ends          { @include u-margin--ends;}
 .u-margin--sides         { @include u-margin--sides;}

 .u-margin--small         { @include u-margin--small;}
 .u-margin--small--top    { @include u-margin--small--top;}
 .u-margin--small--right  { @include u-margin--small--right;}
 .u-margin--small--bottom { @include u-margin--small--bottom;}
 .u-margin--small--left   { @include u-margin--small--left;}
 .u-margin--small--ends   { @include u-margin--small--ends;}
 .u-margin--small--sides  { @include u-margin--small--sides;}

 .u-margin--none          { @include u-margin--none;}
 .u-margin--none--top     { @include u-margin--none--top;}
 .u-margin--none--right   { @include u-margin--none--right;}
 .u-margin--none--bottom  { @include u-margin--none--bottom;}
 .u-margin--none--left    { @include u-margin--none--left;}
 .u-margin--none--ends    { @include u-margin--none--ends;}
 .u-margin--none--sides   { @include u-margin--none--sides;}
