$menu-item-height: 42px;
$burger-icon-dimension: 30px;
$auth-burger-menu-top-portable-offset: 34px;
$auth-burger-menu-left-portable-offset: 18px;

$height-desk: 118px;
$nav-border-color: #d8d8d8;


.luxity-wordpress-menu-container {
  position: relative;

  .actions-wrapper__currency-converter {
    display: none;
  }

  .luxity-wordpress-menu-container__auth-burger-menu {
    display: none;
    background: none;
    border: none;
    outline: none;
    position: absolute;
    left: $auth-burger-menu-left-portable-offset;
    top: $auth-burger-menu-top-portable-offset;

    .icon {
      padding: 0;

      svg {
        width: $burger-icon-dimension;
        height: $burger-icon-dimension;
        & path {
          fill: $clr-brand;
        }
      }
    }
  }
}

.luxity-wordpress-menu-container--authed {
  @extend .luxity-wordpress-menu-container;
  /**
  Hide natural burger menu that comes with wordpress menu when you logged in
  Replaced with luxity-wordpress-menu-container__auth-burger-menu that triggers client menu
  */

  @include media-query(portable) {
    .luxity-wordpress-menu-container__auth-burger-menu {
      display: block;
    }

    #side-nav-button {
      display: none;
    }
  }
}

.luxity-wordpress-menu-container--loading {
  height: $height-desk;
  border-bottom: 1px solid $nav-border-color ;
  position: relative;
  .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    .loader__ellipsis {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}
