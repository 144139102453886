.border-brand {
  transition: 0.2s ease-in-out;
  border: 1px solid $clr-brand;
}

.border-success {
  transition: 0.2s ease-in-out;
  border: 1px solid $clr-success;
}

.border-warning {
  transition: 0.2s ease-in-out;
  border: 1px solid $clr-warning;
}
