@use "sass:math";
$action-dimension: 43px;
$items-buttom-border-margin: 7px;

.dashboard-content {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  &__row {
    display: flex;
    width: 100%;
    border: 1px solid $border-color;
    height: $action-dimension;
    margin-bottom: $items-buttom-border-margin;
    justify-items: start;
    align-items: center;
  }

  &__column {
    flex-basis: 20%;
  }

  &__img {
    height: 30px;
    min-width: 0;

    @media only screen and (max-width: 600px) {
      width: 65%;
      height: auto;
    }
  }

  &__images {
    display: flex;
    flex-flow: row nowrap;
    flex-basis: 100%;

    @media only screen and (max-width: 600px) {
      flex-flow: column nowrap;
    }
  }

  &__ellipsis {
    align-self: center;
    vertical-align: top;
    font-size: $fs-h5;

    @media only screen and (max-width: 600px) {
      font-size: $fs-h2;
      padding: 0 50px 0 0;
    }
  }
}
